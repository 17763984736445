.input-box{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.ticket-input{
    border: 0;
    background-color: #EBEBEB;
    border-radius: 15px;
    height: 35px;
    padding-left: 15px;
    padding-right: 15px;
  }

.qrCode{
    width: 50%;
}

.data{
    width: 262px;
}

.ticket-balance{
    display: flex;
}

.ticket-balance__value{
    margin-top: auto;
    margin-bottom: auto;
}

.ticket-data{
    width: 50%;
    margin: 1rem auto;
}

.ticket-logo{
    width: 100%;
    text-align: center;
}

.ticket-title{
    font-size: 24px;
}

.ticket-change-value{
    font-size: 20px;
}

.ticket-logo{
    max-width: 75%;
    margin: 0 auto;
}

.popup {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    opacity: 0;
    animation: fadeInOut 3s linear forwards;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}
